<template>
	<div :style="bg" >
		<Header style="background-color: transparent;"></Header>
		<div class="column-center-content main-content" style="margin-top: 70%; margin-bottom:  50px;">
			<div :style="boxBg" style="display: flex; flex-direction: column; border-radius: 10px; padding: 10px 16px; margin-bottom: 10px;">
				<label style="line-height: 40px; font-size: 20px; font-weight: bold;">非洲矿业基金第二期</label>
				<div style="background-color: #C3C3C3; height: 1px; width: 100%;"></div>
				<label style="width: 100%; margin: 15px 0px; text-align: left; font-size: 14px;">马来西亚比特国际投资银行银行发行，非洲矿业资源托底</label>
				<div class="btn-content" style="margin-bottom: 0px; margin-top: 0px; width: 100%; ">
					<v-btn rounded block @click="goDetail" style="background-color: #DC3545; color: white; height: 40px;">
						立即购买
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../components/NavHeader.vue'
	
	export default {
		name: 'AddressAdd',
		components: {
			Header
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../assets/money/moneyBg.jpg") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				boxBg : {
					width: "90%",
					backgroundImage: "url(" + require("../../assets/login/login-bg.jpeg") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto",
				
				}
			}
		},
		methods: {
			goDetail() {
				this.$router.push({
					"name": "MoneyDetail"
				})
			}
		}
	}
</script>

<style>
</style>
